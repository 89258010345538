import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'
import PricingSection from '../components/pricing-section-new'

const Pricing = () => {
    return (
        <Layout>
            <SEO title="Pricing" />
            <SubHeader title='Pricing'/>
            <PricingSection/>
        </Layout>
    )
}

export default Pricing